import cloneDeep from 'lodash.clonedeep';
import services from '@/services/admin';

const recent = localStorage.getItem('recent');
const maxRecentItem = 7;

const getOptionsByKeys = (obj) => Object.keys(obj.data).map((key) => ({
  value: key,
  label: obj.data[key],
}));

export const Common = {
  namespaced: true,
  state() {
    return {
      subjects: [],
      chapters: [],
      systemRequirementsOrigin: [],
      systemRequirements: [],
      recent: recent ? recent.split(',') : [],
      showLoader: false,
      demoCounter: 0,
      wsLauncher: null,
      isPortalClosed: false,
    };
  },
  getters: {
    subjects: (state) => state.subjects,
    chapters: (state) => state.chapters,
    systemRequirements: (state) => state.systemRequirements,
    recent: (state) => state.recent,
    showLoader: (state) => state.showLoader,
    demoCounter: (state) => state.demoCounter,
    wsLauncher: (state) => state.wsLauncher,
    isPortalClosed: (state) => state.isPortalClosed,
  },
  actions: {
    async getSubjects({ state, commit }) {
      if (state.subjects.length) { return; }
      const data = await services.getSections();
      commit('SET_SUBJECTS', data);
    },
    async getChapters({ state, commit }) {
      if (state.chapters.length) { return; }
      const data = await services.getSubsections();
      commit('SET_CHAPTERS', data);
    },
    async getSystemRequirements({ commit }) {
      try {
        const data = await services.getSystemReq();
        commit('SET_SYSTEM_REQ', cloneDeep(data.data));
        commit('SET_SYSTEM_REQ_ORIGIN', cloneDeep(data.data));
      } catch (e) {
        console.error(e);
      }
    },
    async setSystemRequirements({ commit }, list) {
      try {
        await services.setSystemReq(list);
        commit('SET_SYSTEM_REQ', cloneDeep(list));
        commit('SET_SYSTEM_REQ_ORIGIN', cloneDeep(list));
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    SET_SUBJECTS: (state, data) => {
      state.subjects = getOptionsByKeys(data);
    },
    SET_CHAPTERS: (state, data) => {
      state.chapters = getOptionsByKeys(data);
    },
    SET_SYSTEM_REQ: (state, data) => {
      state.systemRequirements = data;
    },
    SET_SYSTEM_REQ_ORIGIN: (state, data) => {
      state.systemRequirementsOrigin = data;
    },
    RESET_SYSTEM_REQ: (state) => {
      state.systemRequirements = cloneDeep(state.systemRequirementsOrigin);
    },
    ADD_RECENT: (state, id) => {
      if (!id) { return; }
      const strId = String(id);
      if (state.recent.includes(strId)) {
        state.recent = state.recent.filter((x) => x !== strId);
      }
      state.recent.unshift(strId);
      state.recent = state.recent.slice(0, maxRecentItem);
      localStorage.setItem('recent', state.recent.join(','));
    },
    SET_SHOW_LOADER: (state, boolean) => {
      state.showLoader = boolean;
    },
    SET_DEMO_COUNTER: (state, num) => {
      state.demoCounter = num;
    },
    SET_WS_LAUNCHER: (state, data) => {
      state.wsLauncher = data;
    },
    SET_IS_PORTAL_CLOSED: (state, data) => {
      state.isPortalClosed = data;
    },
  },
};

export default {
  Common,
};
