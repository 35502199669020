import { computed, ComputedRef, ref } from 'vue';
import breakpoints from '@/assets/style/modules.d/breakpoints.module.scss';
import { useWindowResize } from '@/libraries/useWindowResize';

interface UseScreenSize {
  isMobileScreen: ComputedRef<boolean>,
  isTabletScreen: ComputedRef<boolean>,
  isDesktopScreen: ComputedRef<boolean>
}

export const useScreenSize = (): UseScreenSize => {
  const mobileBreakpoint = Number.parseFloat(breakpoints.mobile);
  const tabletBreakpoint = Number.parseFloat(breakpoints.tablet);

  const windowWidth = ref<number>(0);

  const windowResized = () => {
    windowWidth.value = window.innerWidth;
  };

  useWindowResize(windowResized, 100);
  windowResized();

  return {
    isMobileScreen: computed<boolean>(
      () => windowWidth.value <= mobileBreakpoint
    ),
    isTabletScreen: computed<boolean>(
      () => windowWidth.value <= tabletBreakpoint && windowWidth.value > mobileBreakpoint
    ),
    isDesktopScreen: computed<boolean>(
      () => windowWidth.value > tabletBreakpoint
    ),
  };
};

export default useScreenSize;
