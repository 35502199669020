import { i18n } from '@/i18n';
import { SelectOption } from '@/types';

export const locales: Array<string> = i18n.global.availableLocales;

export const localesNamesMapper: Record<string, string> = {
  ru: 'Русский',
  en: 'English',
  fr: 'Français',
  hy: 'Հայերեն',
};

export const localesOptions: Array<SelectOption> = locales.map(
  (locale: string) => ({
    value: locale,
    label: localesNamesMapper[locale] || locale,
  })
);
