<template>
  <TemporaryUnavailable v-if="isPortalClosed && !isSuAccessRole" />

  <div
    v-else
    class="container-flex labs"
  >
    <div
      v-if="isLoading"
      class="labs__skeletonRecent"
    >
      <ElSkeleton animated>
        <template #template>
          <ElSkeletonItem
            class="labs__skeletonRecent__item"
            variant="h3"
          />
        </template>
      </ElSkeleton>

      <ElSkeleton
        :count="4"
        class="labs__skeletonLastCards"
        :loading="isLoading"
      >
        <template #template>
          <CardSkeleton />
        </template>
      </ElSkeleton>
    </div>
    <LabsRecent :products="products" />

    <LabsFilter
      :filterSections="filterSections"
      :isNotFound="isEmpty"
      :hasLicense="hasLicense"
      :countLicense="countLicense"
      @update:license="hasLicense = $event"
      @update:group="updateGroup"
      @update:search="updateSearchInput"
    />
    <ElSkeleton
      animated
      :loading="isLoading"
      :count="4"
      class="labs__skeletonFilter"
    >
      <template #template>
        <ElSkeletonItem
          class="labs__skeletonFilter__item"
          variant="h3"
        />
      </template>
    </ElSkeleton>

    <div
      v-if="isEmpty && searchFilter"
      class="labs__empty"
    >
      {{ t("labs.emptySearch") }}
    </div>

    <ElSkeleton
      animated
      :loading="isLoading"
    >
      <template #template>
        <ElSkeletonItem
          class="labs__skeletonTitle"
          variant="h3"
        />
      </template>
    </ElSkeleton>

    <ElSkeleton
      :count="20"
      class="labs__skeletonCards"
      animated
      :loading="isLoading"
    >
      <template #template>
        <CardSkeleton
          withFooter
          withHeader
        />
      </template>
    </ElSkeleton>

    <TransitionFadeGroup tag="div">
      <LabsCategory
        v-for="[label, categories] in filteredProductsArray"
        v-show="isFiltersNotActive || isActiveFilter(label)"
        :key="label"
        :categories="categories"
      >
        {{ categoryName(label) }}
      </LabsCategory>
    </TransitionFadeGroup>

    <SideMenu v-if="isSuAccessRole" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { ElSkeleton, ElSkeletonItem } from 'element-plus';
import { toast } from '@/utils';
import { catchErrorShow } from '@/utils/errors';

import { LabData, FilterButton } from '@/types/LabData';
import { ProductStateEnum } from '@/types/enums';
import { ErrorCodeEnum } from '@/types/httpResponses';

import LabsRecent from '@/components/Carousel/Recent.vue';
import TransitionFadeGroup from '@/components/TransitionFadeGroup.vue';
import TemporaryUnavailable from '@/router/Alerts/TemporaryUnavailable/index.vue';
import LabsFilter from './components/Filter.vue';
import LabsCategory from './components/Category.vue';
import SideMenu from './components/SideMenu.vue';
import CardSkeleton from './components/CardSkeleton.vue';

import services from './services';

// import fakeData from '@/assets/fakeData/products-license';

const { t } = useI18n();
const store = useStore();

const isPortalClosed = computed(() => store.getters['Common/isPortalClosed']);
const isSuAccessRole = computed(() => store.getters.isSuAccessRole);
const isUser = computed<boolean>(() => store.getters.isAuthed);
const isLoading = ref(false);
const products = ref<LabData[]>([]);

const searchFilter = ref('');
const filterSections = ref<Map<string, FilterButton>>(new Map());
const hasLicense = ref(false);
const countLicense = computed(() => {
  let count = 0;
  filteredProducts.value.forEach((arr, key) => {
    if (!isFiltersNotActive.value && !isActiveFilter(key)) {
      return;
    }
    count += arr.filter((item: LabData) => item.license_state).length;
  });
  return count;
});

const isFiltersNotActive = computed<boolean>(
  () => !Array.from(filterSections.value.values()).some(
    (filter: FilterButton) => filter.active
  )
);

const categoryName = (label: string) => filterSections.value?.get(label)?.name || '';

const filteredProducts = computed<Map<string, LabData[]>>(
  () => products.value.reduce(
    (acc, cur) => {
      if (searchFilter.value && !cur.nameLower.includes(searchFilter.value)) {
        return acc;
      }
      if (hasLicense.value && !cur.license_state) {
        return acc;
      }
      if (!acc.has(cur.section.shortname)) {
        acc.set(cur.section.shortname, [cur]);
      } else {
        acc.get(cur.section.shortname).push(cur);
      }
      return acc;
    },
    new Map()
  )
);

const filteredProductsArray = computed(
  () => Array.from(filteredProducts.value)
);

const isEmpty = computed(() => filteredProducts.value.size === 0);

const createFilterBtn = () => {
  filterSections.value = products.value.reduce((acc, cur: LabData) => {
    if (!acc.has(cur.section.shortname)) {
      acc.set(cur.section.shortname, {
        ...cur.section,
        active: false,
        count: computed(() => filteredProducts.value.get(cur.section.shortname)?.length || 0),
      });
    }
    return acc;
  }, new Map());
};

const getProducts = async () => {
  try {
    isLoading.value = true;

    const data = isUser.value
      ? await services.getLaboratoriesList()
      : await services.getPublicLaboratoriesList();

    products.value = data.reduce((acc: LabData[], product: LabData) => {
      acc.push({ ...product, nameLower: product.name.toLowerCase() });
      return acc;
    }, []);
    // products.value = fakeData;

    if (products.value.length > 0) {
      updatePublishedStatus();
      createFilterBtn();
    }

    if (isPortalClosed.value) {
      store.commit('Common/SET_IS_PORTAL_CLOSED', false);
    }
  } catch (err: any) {
    if (err.response.status === ErrorCodeEnum.BadRequest) {
      if (!isPortalClosed.value) {
        store.commit('Common/SET_IS_PORTAL_CLOSED', true);
      }

      return;
    }

    catchErrorShow(err, t);
  } finally {
    isLoading.value = false;
  }
};

const updateGroup = (key: string) => {
  if (filterSections.value?.get(key)) {
    const elem: FilterButton = filterSections.value?.get(key);
    elem.active = !elem.active;
  }
};

const updateSearchInput = (searchValue: string) => {
  searchFilter.value = searchValue.trim().toLowerCase();
};

const isActiveFilter = (label: string) => filterSections.value.get(label)?.active;

const updatePublishedStatus = () => {
  const wsData = store.getters['Common/wsLauncher'];
  if (!wsData) { return; }

  const lastProduct = getProductByID(wsData.id);
  if (!lastProduct) { return; }
  lastProduct.state = ProductStateEnum.Complete;

  if (wsData.successful) {
    lastProduct.enabled = true;
    toast.success(t('notifications.productPublishedSuccess', { name: lastProduct.name }));
  } else {
    lastProduct.enabled = false;
    const errorText = t('notifications.productPublishedError', { name: lastProduct.name });
    const errorDescription = wsData.error ? `<br/>${t('notifications.productPublishedErrorDescription', { error: wsData.error })}` : '';
    toast.error(errorText + errorDescription);
  }
  store.commit('Common/SET_WS_LAUNCHER', null);
};

watch(() => store.getters['Common/wsLauncher'], (data) => {
  if (!data) { return; }
  updatePublishedStatus();
});

const getProductByID = (id: number) => {
  if (!id) { return null; }
  return products.value
    .find((product: LabData) => product.id === id);
};

getProducts();

</script>

<style lang="scss">
@import "@/assets/style/include.scss";
.labs {
  display: flex;
  flex-direction: column;
  padding: 54px $containerPaddingFlex;
  background: $bgLight1;
  position: relative;

  &__skeletonTitle {
    width: 169px;
    height: 14px;
    background-color: #ebebeb;
  }

  &__skeletonRecent {
    display: flex;
    flex-direction: column;
    gap: $carouselGap;
    padding-top: $carouselGap;
    &__item {
      width: 169px;
      height: 14px;
      background-color: #ebebeb;
    }
    .skeleton {
      @include media-breakpoint-down(xl) {
        &:last-of-type {
          display: none;
        }
      }

      @include media-breakpoint-down(lg) {
        &:nth-child(4) {
          display: none;
        }
      }

      @include media-breakpoint-down(md) {
        &:nth-child(3) {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  &__skeletonLastCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
    gap: $carouselGap;
    padding-bottom: 28px;
  }

  &__skeletonCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(309px, 1fr));
    gap: $carouselGap;
    margin-top: $carouselGap;
  }

  &__skeletonFilter {
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    --el-fill-color: #ffffff !important;
    flex-wrap: wrap;

    &__item {
      width: 165px;
      height: 30px;
      border-radius: 8px;
    }
  }
}

.labs__empty {
  font-style: italic;
  font-size: 20px;
  color: hsl(223, 35%, 51%);
  margin-top: 60px;
  margin-bottom: 60px;
}

@include media-breakpoint-down("tablet") {
  .labs {
    padding: 54px $containerPaddingFlexTablet;
    &__skeletonFilter {
      :last-of-type {
        display: none;
      }
    }
  }
}
@include media-breakpoint-down("mobile") {
  .labs {
    padding: 20px $containerPaddingFlexMobile;
    &__skeletonRecent {
      display: none;
    }
    &__skeletonFilter {
      :nth-child(3) {
        display: none;
      }
    }
  }
}
</style>
