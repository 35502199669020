import { ElNotification } from 'element-plus';
import { DataAttr, MediaFields } from '@/types/manuals';

const findEl = (fieldName, supportedLocales) => {
  let formEl = '';
  const manualType = getManualType(fieldName);
  if (manualType) {
    const langIndex = fieldName.split('.')?.[3];
    const lang = supportedLocales[langIndex];
    formEl = document.querySelector(`#${lang}_${manualType}`);
  } else {
    formEl = document.querySelector(`#${fieldName}`);
  }
  return formEl;
};

const getManualType = (fieldName) => {
  if (fieldName.includes(`${MediaFields.Links}.`)) {
    return MediaFields.Links;
  } if (fieldName.includes(`${MediaFields.VideoInstructions}.`)) {
    return MediaFields.VideoInstructions;
  } if (fieldName.includes(`${MediaFields.UsersGuides}.`)) {
    return MediaFields.UsersGuides;
  }
  return '';
};


const switchToTab = (tabName) => {
  if (tabName) {
    const tabEl = document.querySelector(`#tab-${tabName}`);
    tabEl?.click();
  } else {
    console.error(`атрибут ${DataAttr.DataTabName} не указан`);
  }
};

const switchToLang = (lang) => {
  if (lang) {
    const tabLangEl = document.querySelector(`#lang-box-${lang}`);
    tabLangEl?.click();
  } else {
    console.error(`атрибут ${DataAttr.DataLang} не указан`);
  }
};

const scrollToEl = (formEl) => {
  setTimeout(() => {
    formEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 100);
};

const scrollToError = ({ index, fieldName, supportedLocales }) => {
  if (index > 0 || !supportedLocales.length) { return; }
  const formEl = findEl(fieldName, supportedLocales);
  if (!formEl) {
    console.error('id элемента не задан');
    return;
  }
  switchToTab(formEl.getAttribute(DataAttr.DataTabName));
  switchToLang(formEl.getAttribute(DataAttr.DataLang));
  scrollToEl(formEl);
};

const invalidClass = '--invalid';

const setInvalidFileUploadEl = (fieldName) => {
  const fieldEl = document.querySelector(`[data-el-name="${fieldName}"]`);
  if (fieldEl) {
    fieldEl.classList.add(invalidClass);
  }
};

export const resetAllInvalidFileUploadEl = () => {
  const fieldElList = document.querySelectorAll('[data-el-name]');
  if (fieldElList.length) {
    fieldElList.forEach((el) => {
      el.classList.remove(invalidClass);
    });
  }
};

export const showValidationError = (
  { fields, t, supportedLocales, isScrollToError, showManualTitle }
) => {
  const messages = [];
  if (!fields) { return; }

  let isLinksTitleAdded = false;
  let isVideoTitleAdded = false;
  let isDocTitleAdded = false;
  const linkTitle = t('labels.links');
  const videoTitle = t('labelVideos');
  const docTitle = t('labelDocs');

  Object.keys(fields).forEach((fieldName, index) => {
    setInvalidFileUploadEl(fieldName);
    if (isScrollToError) {
      scrollToError({ index, fieldName, supportedLocales });
    }
    fields[fieldName].forEach((i) => {
      if (showManualTitle
          && !isLinksTitleAdded
          && fieldName.includes(MediaFields.Links)) {
        isLinksTitleAdded = true;
        messages.push(linkTitle);
      } else if (showManualTitle
          && !isVideoTitleAdded
          && fieldName.includes(MediaFields.VideoInstructions)) {
        isVideoTitleAdded = true;
        messages.push(videoTitle);
      } else if (showManualTitle
          && !isDocTitleAdded
          && fieldName.includes(MediaFields.UsersGuides)) {
        isDocTitleAdded = true;
        messages.push(docTitle);
      }

      if (!i.message) { return false; }
      messages.push(i.message);
    });
  });

  ElNotification({
    title: t('notifications.orgShopError'),
    message: messages.join('</br>'),
    type: 'warning',
    position: 'bottom-right',
    dangerouslyUseHTMLString: true,
  });
};
