import axios from 'axios';
import {
  UniversalManualsRequest, GeneralManual, GeneralManualsAddParams,
  SysReqResponseBackend,
} from '@/types/manuals';

const uploadFile = (data: any, config: any): Promise<any> => axios.post('/api/s3/upload', data, {
  ...config,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// разделы
const getSections = (): Promise<{ url: string }> => axios
  .get('/api/sections')
  .then((response) => response.data);

// предметы
const getSubsections = (): Promise<{ url: string }> => axios
  .get('/api/subsections')
  .then((response) => response.data);

const getSupportedLocales = (): Promise<{ url: string }> => axios
  .get('/api/get-supported-locales')
  .then((response) => response.data);

const getKeyCloakId = (
  params: Record<string, string>
): Promise<any> => axios.get('/api/get-keycloak-id', { params })
  .then((response) => response.data);

const getRequiredLocales = (): Promise<any> => axios
  .get('/api/get-necessary-locales')
  .then((response) => response.data);

const createDraft = (value: any): Promise<any> => axios
  .post('/api/product-create/as-draft', value)
  .then((response) => response.data);

const createComplete = (value: any): Promise<any> => axios
  .post('/api/product-create/as-complete', value)
  .then((response) => response.data);

const updateDraft = (value: any): Promise<any> => axios
  .post('/api/product-update/as-draft', value)
  .then((response) => response.data);

const updateComplete = (value: any): Promise<any> => axios
  .post('/api/product-update/as-complete', value)
  .then((response) => response.data);

const updateFromDraftToComplete = (value: any): Promise<any> => axios
  .post('/api/product-update/as-draft-and-make-complete', value)
  .then((response) => response.data);

const syncAllFieldsWithWebLate = (keycloak: string): Promise<any> => axios
  .get(`/api/weblate/all-translations?keycloak_id=${keycloak}`)
  .then((response) => response.data);

const syncFieldWithWebLate = (payload: Record<string, string>): Promise<any> => axios
  .get('/api/weblate/get-translation'
      + `?keycloak_id=${payload.keycloak}&locale=${payload.locale}&input_name=${payload.inputName}`)
  .then((response) => response.data);

const getProduct = (id: number | string): Promise<any> => axios
  .get(`/api/products/${id}/show-for-update`)
  .then((response) => response.data);

const deleteProduct = (id: number | string): Promise<any> => axios
  .delete(`/api/product/${id}`)
  .then((response) => response.data);

const restoreProduct = (id: number | string): Promise<any> => axios
  .post(`/api/product/${id}/restore`)
  .then((response) => response.data);

const getGeneralManuals = (params: UniversalManualsRequest):
    Promise<GeneralManual[]> => axios
  .get('/api/universal-manuals', { params })
  .then((res) => res.data);

const updateGeneralManuals = (params: GeneralManualsAddParams):
    Promise<any> => axios
  .post('/api/universal-manuals/sync', params)
  .then((res) => res.data);

const getSystemReq = (): Promise<any> => axios.get('/api/system-requirements')
  .then((response) => response.data);

const setSystemReq = (params: SysReqResponseBackend[]):
    Promise<any> => axios
  .post('/api/system-requirements', { data: params })
  .then((response) => response.data);

export default {
  uploadFile,
  getSections,
  getSubsections,
  getSupportedLocales,
  getKeyCloakId,
  getRequiredLocales,
  createDraft,
  createComplete,
  updateDraft,
  updateComplete,
  syncAllFieldsWithWebLate,
  syncFieldWithWebLate,
  getProduct,
  deleteProduct,
  restoreProduct,
  updateFromDraftToComplete,
  getGeneralManuals,
  updateGeneralManuals,
  getSystemReq,
  setSystemReq,
};
