import { VueI18n } from 'vue-i18n';
import { ProfileField, SelectOption } from '@/types';
import { useRoleActionAccess } from '@/composition/useRoleActionAccess';
import { isAdmin, isSU, isUsual } from '@/utils/user';

const {
  canDoActionByArray,
  canDoActionByRoleId,
  roleId,
  role,
} = useRoleActionAccess();

export const generateNewProfileField = (
  field: ProfileField,
  value: string,
  t: VueI18n['t'],
  opts?: Array<SelectOption>,
  name?: string,
  isOwnProfile: boolean = false,
): ProfileField => {
  let can_edit = true;
  let can_see = true;
  let options = opts;

  if (Array.isArray(field.can_edit)) {
    can_edit = canDoActionByArray(field.can_edit);
  }
  if (Array.isArray(field.can_see)) {
    can_see = canDoActionByArray(field.can_see);
  }

  if (name && name === 'role' && !isAdmin(role.value)) {
    // usual user can switch between student and teacher
    const isUsualUserOwnProfile = isUsual(role.value) && isOwnProfile;

    can_edit = !value
      || canDoActionByRoleId(Number(value))
      || isUsualUserOwnProfile;

    if (!isSU(role.value) && !isUsualUserOwnProfile && can_edit) {
      options = opts?.filter(({ value: val }) => val > roleId.value) || [];
    }
  }

  return {
    ...field,
    value,
    oldValue: value,
    label: `${t(field.label)}${field.required ? '*' : ''}`,
    options,
    can_edit,
    can_see,
  };
};

export { getFileExtension } from './files';
export { createOptionsFromArray } from './select';

export default {
  generateNewProfileField,
};
