import { SelectOption } from '@/types';

// TODO think about types (findOrgByText)
export const createOptionsFromArray = (
  data: Array<unknown>
): SelectOption[] => data.map((item: any) => ({
  label: item?.name,
  value: item?.id,
  shortname: item?.shortname,
}));

export default {
  createOptionsFromArray,
};
