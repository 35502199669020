<template>
  <ElRow class="general-table-row table-row__row">
    <ElCol
      :span="1"
      class="table-row__column"
    >
      <div
        v-if="!item.isDisabled"
        class="table-row__column-content table-row__first-column"
      >
        <div class="handle-dnd">
          <div
            class="table-row__dnd-button"
            :class="{ 'use-hover': !drag }"
          >
            <BurgerIcon />
          </div>
        </div>
      </div>
    </ElCol>

    <ElCol
      v-if="isVideoOrDoc"
      :span="2"
      class="table-row__column"
    >
      <div
        v-if="!item.isDisabled"
        class="table-row__column-content table-row__type"
      >
        <div
          v-if="isCommon"
          class="general-table-row__ellipsis"
        >
          {{ flagLabel }}
        </div>
        <template v-else>
          {{ isFile ? t('labelFile') : t('fields.url') }}
        </template>
      </div>
    </ElCol>
    <ElCol
      :span="isHideSelect ? 13 : 9"
      class="table-row__column"
    >
      <div class="table-row__column-content">
        <ElFormItem :prop="`${fieldName}.${index}.locales.${indexLang}.file`">
          <FileUpload
            v-if="isFile"
            ref="uploadInput"
            :accept="acceptFile"
            class="fullwidth"
            :url="itemUrl"
            :error="hasError"
            :disabledCopy="notSaved || !itemUrl"
            :disabled="isCommon"
            :data-el-name="`${fieldName}.${index}.locales.${indexLang}.file`"
            @fileLoading="fileLoading"
            @fileUpdate="changeFile"
            @hasError="catchError"
          />
          <UiInput
            v-else
            :value="itemUrl"
            class="upload-file__input"
            :class="{ 'table-row__row-blue': isColored }"
            copy
            :disabled="item.isDisabled || isCommon"
            :data-el-name="`${fieldName}.${index}.locales.${indexLang}.file`"
            @input="handleLinkInput"
          />
        </ElFormItem>
      </div>
    </ElCol>

    <ElCol
      v-if="isVideoOrDoc && !isHideSelect"
      :span="4"
      class="table-row__column"
    >
      <div
        v-if="!item.isDisabled"
        class="table-row__column-content table-row__roles"
      >
        <ElFormItem :prop="`${fieldName}.${index}.flag`">
          <UiSelect
            :value="item.flag"
            :options="defaultFlags"
            :data-el-name="`${fieldName}.${index}.flag`"
            @change="handlerChangeFlag"
          />
        </ElFormItem>
      </div>
    </ElCol>

    <ElCol
      v-if="isLink"
      :span="3"
      class="table-row__column"
    >
      <div
        v-if="!item.isDisabled"
        class="table-row__column-content add-link-table-row__roles"
      >
        <ElTooltip
          v-for="{ name, value, icon, active } in roles"
          :key="value"
          :content="name"
          :enterable="false"
          placement="bottom"
          effect="light"
        >
          <div
            class="add-link-table-row__role"
            :class="{ active }"
            @click="switchRole(value)"
          >
            <component :is="icon" />
          </div>
        </ElTooltip>
      </div>
    </ElCol>

    <ElCol
      :span="isVideoOrDoc ? 8 : 7"
      class="table-row__column"
    >
      <div class="table-row__column-content">
        <ElFormItem :prop="`${fieldName}.${index}.locales.${indexLang}.caption`">
          <UiInput
            :disabled="item.isDisabled || isCommon"
            :value="buttonText"
            :maxlength="18"
            :placeholder="btnPlaceholderText"
            :showCounter="!item.isDisabled"
            :class="{ 'button-text-input': !isInputFocused }"
            :data-el-name="`${fieldName}.${index}.locales.${indexLang}.caption`"
            @focus="isInputFocused = true"
            @blur="isInputFocused = false"
            @input="handleTextButtonInput"
          />
        </ElFormItem>
      </div>
    </ElCol>

    <div
      v-if="!item.isDisabled && !isCommon"
      class="table-row__delete-button"
      @click="handleDelete"
    >
      <Close />
    </div>
  </ElRow>
</template>

<script setup lang="ts">
import { ElCol, ElRow, ElFormItem, ElTooltip } from 'element-plus';
import { toRef, computed, ref } from 'vue';
import { Close } from '@element-plus/icons-vue';
import { useI18n } from 'vue-i18n';
import BurgerIcon from '@/components/icons/Burger.vue';
import UiInput from '@/components/Form/UiInput.vue';
import {
  Link,
  ManualFlags,
  MediaFileEnum,
  DocumentType,
  DocumentTypeEnum,
  Locale,
  LinksRolesEnum, LinkRole,
} from '@/types/manuals';
import UiSelect from '@/components/Form/UiSelect.vue';
import { getDefaultFlags, acceptVideo, acceptDocument, getFieldByType } from '@/router/Admin/builderManuals';
import FileUpload from '@/components/Form/FileUpload.vue';
import { UploadedPayloadType } from '@/utils/fileUploader';
import { resetAllInvalidFileUploadEl } from '@/utils/validationRules';
import RoleAdmin from '@/components/icons/RoleAdmin.vue';
import RoleTeacher from '@/components/icons/RoleTeacher.vue';
import RoleStudent from '@/components/icons/RoleStudent.vue';

const { t } = useI18n();

const props = defineProps<{
  item: Link,
  localeMedia: Locale,
  index: number, // массив первого уровня, список инструкций
  indexLang: number,
  drag: boolean,
  type: DocumentType,
  isHideSelect?: boolean,
}>();

const btnPlaceholderText = computed(() => {
  const { locales } = props.item;
  if (!locales.length) { return ''; }
  const filledLocale = locales.find((loc: Locale) => loc.caption);
  return filledLocale?.caption ? filledLocale.caption : '';
});

const emit = defineEmits<{
  (event: 'inputFileLink', value: { index: number, value: string, needFillAnotherFields: boolean }): void;
  (event: 'inputTextButton', value: { index: number, value: string }): void;
  (event: 'delete', value: number): void;
  (event: 'changeFile', value: { data: UploadedPayloadType, index: number, indexLang: number }): void;
  (event: 'changeFlag', value: { index: number, value: ManualFlags }): void;
  (event: 'switchRoles', value: { index: number, value: LinkRole[] }): void;
}>();

const isFileLoading = ref<boolean>(false);
const uploadInput = ref<InstanceType<typeof FileUpload> | null>(null);
const item = toRef(props, 'item');

const isCommon = ref(Boolean(props.item.parent_id));
const defaultFlags = getDefaultFlags(t);
const flagLabel = computed(
  () => defaultFlags.find((flag) => flag.value === item.value.flag)?.label
);

const notSaved = computed<boolean>(() => !item.value.locales);
const isVideo = props.type === DocumentTypeEnum.Video;
const isLink = props.type === DocumentTypeEnum.Link;
const isVideoOrDoc = !isLink;
const fieldName = getFieldByType(props.type);
const acceptFile = isVideo ? acceptVideo : acceptDocument;

const buttonText = computed(() => props.localeMedia?.caption || '');
const itemUrl = computed(() => props.localeMedia?.file || '');
const isFile = computed(() => props.localeMedia?.file_type === MediaFileEnum.File);

const isInputFocused = ref<boolean>(false);

const isColored = computed(() => {
  if (!isLink) { return false; }
  return props.localeMedia?.filledLang === props.localeMedia.locale;
});

const rolesDefault = [{
  name: t('roles.admin'),
  value: LinksRolesEnum.Admin,
  icon: RoleAdmin,
  active: false,
},
{
  name: t('roles.teacher'),
  value: LinksRolesEnum.Teacher,
  icon: RoleTeacher,
  active: false,
},
{
  name: t('roles.student'),
  value: LinksRolesEnum.Student,
  icon: RoleStudent,
  active: false,
}];

const roles = computed(() => rolesDefault.map((role) => ({
  ...role,
  active: props.item.for_roles ? props.item.for_roles.includes(role.value) : false,
})));

const switchRole = (role: LinkRole) => {
  if (!props.item.for_roles) { return; }
  const innerRoles = [...props.item.for_roles];
  const index = innerRoles.indexOf(role);
  if (index !== -1) {
    innerRoles.splice(index, 1);
  } else {
    innerRoles.push(role);
  }

  emit('switchRoles', { index: props.index, value: innerRoles });
};

const handlerChangeFlag = (value: ManualFlags) => {
  emit('changeFlag', { index: props.index, value });
};
const handleLinkInput = (value: string) => {
  emit('inputFileLink', { index: props.index, value, needFillAnotherFields: isLink });
};

const handleTextButtonInput = (value: string) => {
  emit('inputTextButton', { index: props.index, value });
};

const fileLoading = (bool: boolean) => {
  hasError.value = false;
  resetAllInvalidFileUploadEl();
  isFileLoading.value = bool;
};

const changeFile = (data: UploadedPayloadType) => {
  hasError.value = false;
  emit('changeFile', { data, index: props.index, indexLang: props.indexLang });
};

const hasError = ref(false);
const catchError = () => {
  hasError.value = true;
};

const handleDelete = () => {
  emit('delete', props.index);
};

</script>


<style lang="scss">
@import "@/assets/style/include.scss";

.general-table-row {

  &__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-row__column .el-input.is-disabled {
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
  }

  .el-form-item {
    width: 100%;
    margin-bottom: 0;
  }
}

.add-link-table-row {
  &__roles {
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 4px;
  }

  &__role {
    width: $input-height;
    height: $input-height;
    display: flex;
    justify-content: center;
    align-items: center;
    border: $input-border;
    border-radius: $form-border-radius;
    color: $colorBlue1;
    cursor: pointer;
    background-color:$color-white;

    &.active {
      background-color: $colorBlue1;
    }

    &:focus-visible {
      outline: none;
      border-color: $input-border;
    }

    &:hover,
    &:focus-visible {
      background-color: $colorBlueHover;
    }

    &.active,
    &:hover {
      border-color: currentColor;
      color: $color-white;
    }
  }
}
</style>
