// eslint-disable-next-line no-shadow
export const enum LicenseState {
  Trial = 'licenses.license_state_trial',
  Subscribed = 'licenses.license_state_own',
  Organization = 'licenses.license_state_student',
}

export enum SizeSkeletonCard {
  X = 'x',
  S = 's',
  M = 'm',
}

export const skeletonCads = [
  { id: 0, size: SizeSkeletonCard.S },
  { id: 1, size: SizeSkeletonCard.X },
  { id: 2, size: SizeSkeletonCard.M },
];
