<template>
  <div
    class="video-card"
    :class="{ 'video-card_click-active': isClickActive }"
    @mousedown="isClickActive = true"
    @mouseup="isClickActive = false"
    @mouseleave="isClickActive = false"
  >
    <div class="video-card__wrap">
      <a
        v-if="isLink(card)"
        :href="file"
        class="video-card__link"
        target="_blank"
      >
        <div class="video-card__img-box">
          <img
            src="/img/empty/placeholderBg.webp"
            :alt="text"
            class="video-card__img"
          />
        </div>
        <h3 class="video-card__video-title">
          {{ text }}
        </h3>
      </a>

      <VideoPlayer
        v-else
        :path="file"
        :title="text"
        :autoplay="true"
        width="70%"
        openButtonCssClass="video-card__btn"
        :openButtonText="text"
      >
        <template #video-preview>
          <div class="video-card__img-box">
            <video
              :src="file"
              preload="metadata"
              class="video-card__img"
            />
            <span class="video-card__img-circle" />
          </div>
        </template>
        <template #btn-text>
          <h3 class="video-card__video-title">
            {{ text }}
          </h3>
        </template>
      </VideoPlayer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import VideoPlayer from '@/components/VideoPlayer/index.vue';
import { ManualViewTypeEnum, MediaFile } from '@/types/manuals';

const store = useStore();

const props = defineProps<{
  card: MediaFile;
}>();

const isLink = (item: MediaFile) => item.view_type === ManualViewTypeEnum.open;
const currentLocaleIndex = computed(() => store.getters['Locales/currentLocaleIndex']);

const file = computed(() => props.card.locales[currentLocaleIndex.value].file);
const text = computed(() => props.card.locales[currentLocaleIndex.value].caption);

const isClickActive = ref<boolean>(false);
</script>

<style lang="scss">
@import "@/assets/style/include";

.video-card {
  position: relative;
  width: 100%;
  transition: border-color $transition-speed;
  padding: 0 8px;
  font-size: 0;

  &,
  &__wrap {
    display: flex;
    height: 100%;
  }

  &_click-active .video-card__img-circle {
    opacity: 0.3;
  }

  &__wrap {
    border-radius: 14px;
    border: 1px solid $color-disabled;
    overflow: hidden;
    cursor: pointer;
    transition: border-color $transition-speed;

    &:hover:not(:active) {
      border-color: $colorBlue1;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__video-cover {
    width: 100%;
    height: 136px;
    object-fit: cover;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
  }

  &__video-title {
    padding: 8px 10px;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: hsl(0, 0%, 16%);
    background-color: #fafafa;
    border-top: none;
  }

  &__img-box {
    flex-grow: 1;
    position: relative;

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      display: block;
      background: url("@/assets/svg/play.svg") no-repeat calc(50% + 5px) center;
      opacity: 0;
      transition: opacity $transition-speed;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__img-circle {
    width: 88px;
    height: 88px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    background-color: $color-white;
    opacity: 0;
    border-radius: 50%;
    transition: opacity $transition-speed;
  }

  &:hover {
    .video-card__img-box:before {
      opacity: 1;
    }
  }
  &:active {
    .video-card__img-box:after {
      opacity: .5;
    }
  }
}

.carousel.is-dragging {
  .video-card{
    &:active .video-card__img-box:after {
      opacity: 0;
    }
  }
}

@include media-breakpoint-down("mobile") {
  .video-card {
    padding-left: 0;
    padding-right: 4px;
  }
}

</style>
