import { NamedValue } from '@intlify/core-base';
import { computed } from 'vue';
import { Lang } from '@/types/manuals';
import { i18n } from '@/i18n';

const vrLabsLink = 'https://vr-labs.ru';

const mapLang: Partial<Record<Lang, string>> = {
  hy: 'am',
};
const lang = computed(() => {
  const currentLang = i18n.global.locale.value as Lang;
  return mapLang[currentLang] ? mapLang[currentLang] : currentLang;
});

export const currentCopyright = (
  t: (key: string, named: NamedValue) => string,
  date: string
): string => t('footer.copyright', { date });

export const currentSupportLink = computed(() => `${vrLabsLink}/${lang.value}/support-${lang.value}/`);

export const currentImgPath = '/img/example/xls_example.jpg';

export const currentEmail = 'info@vizex.ru';

export const currentSupportEmail = 'supportviz@answer-42.ru';

export const companyWebsiteLink = computed(() => `${vrLabsLink}/${lang.value}`);
