export const isLicenseSoonExpires = (
  expirationDate: string | undefined,
  hoursUntilExpiration: number,
): boolean => {
  if (!expirationDate) {
    return false;
  }

  const timeDiff = new Date(expirationDate).getTime() - new Date().getTime();

  return timeDiff / 1000 / 60 / 60 < hoursUntilExpiration;
};
