export const orgUnits = {
  students: '',
  teachers: '',
  moderators: '',
};

export const correctTypeInviteLink: Array<string> = [
  'accepted',
  'consists',
  'consists-another',
  'confirmation-await',
  'incorrect-role',
];
