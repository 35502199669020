import { RouteLocationRaw } from 'vue-router';
import { names, permissions, ADMIN_PATH, LOCAL_HOST } from '@/constants/routes';

const baseAdminUrl = process.env.NODE_ENV === 'production'
  ? `/${ADMIN_PATH}/`
  : LOCAL_HOST;

export interface MenuItem {
  baseUrlLink?: string,
  link: RouteLocationRaw;
  title: string;
  permissions: Array<string>;
  disabled?: boolean;
}

export const mainMenu: Array<MenuItem> = [
  {
    link: { name: names.laboratories },
    title: 'menus.laboratories',
    permissions: permissions.laboratories,
  },
  {
    link: { name: names.shop },
    title: 'menus.shop',
    permissions: permissions.shop,
  },
  {
    link: '',
    baseUrlLink: `${baseAdminUrl}invoices`,
    title: 'menus.shop',
    permissions: permissions.invoices,
  },
  {
    baseUrlLink: `${baseAdminUrl}users`,
    link: {},
    title: 'menus.administration',
    permissions: permissions.administration.root,
  },
  {
    baseUrlLink: `${baseAdminUrl}price`,
    link: {},
    title: 'menus.prices',
    permissions: permissions.price,
  },
  {
    link: { name: names.subscribe },
    title: 'menus.subscriptions',
    permissions: permissions.subscribe,
  },
];
