import { roles, suAccessRoles, suRoles } from '@/constants/roles';

export const UserData = {
  state() {
    return {
      user: null,
      token: null,
      isLoggingOut: false,
    };
  },
  getters: {
    isAuthed: (state) => {
      if (!state.user) {
        return false;
      }
      return Object.keys(state.user).length > 0;
    },
    user: (state) => state.user,
    lang: (state) => state.user?.locale || null,
    role: (state) => state.user?.role?.shortname || roles.guest,
    isLoggingOut: (state) => state.isLoggingOut,
    roleOption: (state) => [
      { value: state.user?.role?.id, label: state.user?.role?.name },
    ],
    isOrgAttached: (state) => Boolean(state.user?.organization),
    roleId: (state) => state.user?.role?.id || 10,
    userId: (state) => state.user?.id || null,
    organizationId: (state) => state.user?.organization?.id || null,

    // todo: add role here and use from one place! clean every components realisation
    isAdmin: (state, rootGetters) => rootGetters.role === roles.admin,
    isModerator: (state, rootGetters) => rootGetters.role === roles.moderator,
    isMarketer: (state, rootGetters) => rootGetters.role === roles.marketer,
    isDistributor: (state, rootGetters) => rootGetters.role === roles.distributor,
    isSuRole: (state, rootGetters) => suRoles.includes(rootGetters.role),
    isOrgOwner: (state, rootGetters) => rootGetters.role === roles.orgOwner,
    isOrgModerator: (state, rootGetters) => rootGetters.role === roles.orgModerator,
    isSuAccessRole: (state, rootGetters) => suAccessRoles.includes(rootGetters.role),
    isTeacher: (state, rootGetters) => rootGetters.role === roles.teacher,
    isStudent: (state, rootGetters) => rootGetters.role === roles.student,
    isGuest: (state, rootGetters) => rootGetters.role === roles.guest,
  },
  actions: {
    clearUserData({ commit }) {
      commit('SET_USER', null);
    },
  },
  mutations: {
    SET_USER(state, value) {
      state.user = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_IS_LOGGING_OUT(state, bool) {
      state.isLoggingOut = bool;
    },
  },
};

export default {
  UserData,
};
