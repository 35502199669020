import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import dayjs from '@/utils/dayjs';

import { formatDate } from '@/utils';
import { PortalLocaleEnum } from '@/types/enums';

interface IExpirationDate {
  expirationDate: dayjs.ConfigType,
  isActive: boolean,
  showTime: boolean,
}

/*
Варианты форматов:
1. Дата: Истекает 12 декабря 2024 г. / Expires 12 December 2024
2. Дата + время: Истекает 12 декабря 2024 г. в 11:00 / Expires 12 December 2024 at 11:00 AM
*/

const useExpirationDateText = (params: IExpirationDate): string => {
  const {
    expirationDate,
    isActive,
    showTime,
  } = params;

  const { t } = useI18n();

  const isRu = computed(() => store.getters.lang === PortalLocaleEnum.Russian);
  const isEn = computed(() => store.getters.lang === PortalLocaleEnum.English);

  const expirationDateFormat = 'DD MMMM YYYY';
  const expirationTimeFormat = isEn.value ? 'HH:mm A' : 'HH:mm';

  const formattedDate = `${formatDate(expirationDate, expirationDateFormat)} ${isRu.value ? 'г.' : ''}`;
  const expirationTime = dayjs(expirationDate).format(expirationTimeFormat);

  if (showTime) {
    return `${isActive ? t('expires') : t('expired')} ${formattedDate} ${t('prepositions.at')} ${expirationTime}`;
  }

  return `${isActive ? t('expires') : t('expired')} ${formattedDate}`;
};

export default useExpirationDateText;
