import { paths } from '@/constants/routes';

export const useRegExp: Record<string, RegExp> = {
  numRegExp: /[-\d]/,
  correctNumber: /^-?(?:\d+(?:[.,]\d*)?)?$/,
  correctUrl: /^https?:\/\//,
};

export const sessionClosedRegExp = new RegExp(
  `${paths.alerts.sessionClosed}$`, 'gm'
);
