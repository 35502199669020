<template>
  <svg v-bind="attrs">
    <path
      d="M2.625 16C2.14375 16 1.73177 15.8368 1.38906 15.5104C1.04635 15.184 0.875 14.7917 0.875 14.3333V4H0V2H4.375V0H9.625V2H14V4H13.125V14.3333C13.125 14.7917 12.9536 15.184 12.6109 15.5104C12.2682 15.8368 11.8562 16 11.375 16H2.625Z"
      fill="currentColor"
    />
    <path
      d="M4.18018 11.4006L5.77033 12.9908L11.8155 6.94562L10.2253 5.35547L5.7735 9.80731L3.84699 7.88079L2.25684 9.47095L4.18018 11.4006Z"
      fill="#5ECAEC"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 14, height: 16 });
</script>
