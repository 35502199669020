export enum PortalLocaleEnum {
  Russian = 'ru',
  English = 'en',
  French = 'fr',
  Armenian = 'hy',
}

export enum ProductTypeEnum {
  Package = 'packet',
  Laboratory = 'lab',
}

export enum ProductStateEnum {
  Draft = 'draft',
  Complete = 'complete',
  Publication = 'on_publication',
  RePublication = 'on_republication',
  MarkedForDeletion = 'on_deleting',
  Deleted = 'deleted',
}

export enum SubscriptionPeriodEnum {
  Month = 1,
  ThreeMonths = 3,
  HalfYear = 6,
  Year = 12,
}

export enum SubscriptionStatusEnum {
  Expired = 0,
  Active = 1,
}

export enum SubscriptionAutorenewalEnum {
  Off = 0,
  On = 1,
}
