export default {
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscribirse"])},
  "issueTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitir hasta"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está usted seguro?"])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear"])},
  "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear"])},
  "banUnban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear / Desbloquear"])},
  "createNewOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un nuevo usuario"])},
  "orgKick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir de la institución"])},
  "leaveOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desacoplar la"])},
  "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree un grupo de"])},
  "createModerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un moderador"])},
  "createOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una institución"])},
  "changeOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la institución"])},
  "deleteOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar la institución"])},
  "systemRequirements": {
    "systemRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisitos del sistema"])}
  },
  "profile": {
    "accepted": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su solicitud es aceptada!"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos aceptado su solicitud de entrada en el organismo. Ver el estado de la solicitud o cancelar en su perfil."])}
    },
    "leaveOrgText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si deja la institución, ya no estarán disponibles en la suscripción, emitidos por la institución."])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar la solicitud"])},
    "modalTitleRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recuperación de usuario"])},
    "modalTextRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted realmente quiere recuperar ese usuario?"])},
    "attachToOrgText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Desea enviar su solicitud de adhesión en \"", _interpolate(_named("org")), "\"?"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la contraseña &gt;"])},
    "waitingForConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En espera de confirmación"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
    "deleteModalMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Seguro que quieres eliminar tu cuenta? Si tienes algún problema con tu cuenta envíenos un correo electrónico a ", _interpolate(_list(0)), " y vamos a tratar de ayudarle."])},
    "subscribeMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscribirse"])},
    "deleteModalMessageOrg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Está seguro de que desea eliminar su cuenta y la institución? Si tienes algún problema con tu cuenta envíenos un correo electrónico a ", _interpolate(_list(0)), " y vamos a tratar de ayudarle."])},
    "deleteModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La eliminación de la cuenta"])},
    "deleteModalHeaderOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La eliminación de la institución"])},
    "restoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar la cuenta"])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar cuenta"])},
    "deleteButtonOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar la institución"])},
    "revokeOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar la solicitud"])},
    "revokeOrgRequestText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Desea cancelar su solicitud de adhesión en \"", _interpolate(_named("org")), "\"?"])},
    "leaveOrgButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепиться de la escuela &gt;"])},
    "leaveOrgModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desacoplar de la institución"])},
    "leaveOrgModalConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепиться"])},
    "disabledDeleteOrgTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede quitar de la institución, mientras que en ello hay alguien, además de fundador"])},
    "consists": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted ya está en la institución"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhorabuena, nada malo ha ocurrido. Usted sólo se trató de volver a entrar en el establecimiento, en el que ya está"])}
    },
    "consists-another": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted ya está en la institución"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por desgracia, no se puede entrar en varias instituciones a la vez. Si usted desea cambiar de institución, primero открепитесь de la actual"])}
    },
    "confirmation-await": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su solicitud de examen"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos aceptado su solicitud de admisión de la institución. Su solicitud se encuentra pendiente de"])}
    },
    "incorrect-role": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafortunadamente, usted no puede ir a este enlace"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambie la función a su perfil o pida al administrador para cambiar su"])}
    }
  },
  "shop": {
    "personalDataProcessingInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Continuando con la compra, usted acepta ", _interpolate(_list(0)), ", ", _interpolate(_list(1)), " y ", _interpolate(_list(2)), "."])},
    "nextPaymentSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("suma")), " en el mes"]), _normalize([_interpolate(_named("suma")), " en seis meses"]), _normalize([_interpolate(_named("suma")), " en el año"])])},
    "buy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comprar seleccionado"]), _normalize(["Comprar"])])},
    "personalDataProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tratamiento de datos personales"])},
    "licenseAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la oferta pública"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["condiciones de uso"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidad"])},
    "textNotAuthorizedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para suscribirse a un laboratorio - cree una cuenta y confirme el correo"])},
    "autopaymentLabelAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["автоплатеж (se puede cancelar más tarde, en la oficina de personal)"])},
    "nextPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El siguiente pago ", _interpolate(_named("date")), ":"])},
    "periodPayment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["cuando el pago de todos los laboratorios en 1 mes"]), _normalize(["al pago de todos los laboratorios en los de 6 meses"]), _normalize(["al pago de todos los laboratorios en 12 meses"])])},
    "tillDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["hasta el ", _interpolate(_named("date"))])},
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar el pedido"])},
    "sendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El producto"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencias"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un pedido"])},
    "orgEmptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para obtener acceso a los laboratorios y dar licencia a sus alumnos y a los profesores, hacer el pedido y siga las instrucciones."])},
    "orgEmptyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se necesita acceso para la organización?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la estaca-en de"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacío"])},
    "closeShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este momento, la tienda está cerrada"])},
    "unavailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compre un \"laboratorio Virtual\" para su escuela y de los estudiantes ahora!"])},
    "unavailableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre la adquisición de licencias, póngase en contacto con nosotros:"])},
    "unavailableForUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>El precio de la licencia para un solo usuario en un año: <span style=\"white-space: nowrap\">43 037 ₽.</span></b>\n\nEl software \"laboratorio Virtual\" (15 laboratorios) se distribuye según el modelo SaaS (del inglés. software as a service — software como servicio), es decir, no se pone en un único equipo, y solo se descarga desde internet a través del navegador web. Cada usuario tiene su cuenta personal, donde almacena sus descargados de la escena y puede compartirlos con otros usuarios.\n\nLa licencia del software \"laboratorio Virtual\" se adquiere en cada usuario (profesor o estudiante) por separado para su uso con el escolar y el equipo de casa. El uso de una licencia comprada por varios usuarios, no está permitido.\n\nLa adquisición de licencias en toda la escuela o la clase es un sistema flexible de descuentos, cuyo tamaño se determina individualmente en función del número de personales de licencias.\n\nPrecios de licencias se conceden en forma oficial la cotización previa petición por correo electrónico <a href=\"mailto:infoviz", "@", "answer-42.ru\">infoviz", "@", "answer-42.ru</a>.\n\n<b>El pago para las personas físicas mediante el pago en línea se conecta más tarde.</b>\n\nSiempre con ustedes, el equipo de VR-Labs.\n\n<i style=\"opacity: 0.5\">No es una oferta pública. Los precios son de acuerdo con la lista de precios del titular de los derechos. El titular se reserva el derecho de modificar los precios en cualquier momento.</i>\n"])},
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El laboratorio"])},
    "ordersListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de pedidos"])},
    "notPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se paga"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizado"])},
    "noStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está definido"])}
  },
  "administration": {
    "org": {
      "actions": {
        "downloadFileWithErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar el documento con errores"])},
        "brokenFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo no cumple con los requisitos, pruebe con otro archivo."])},
        "accountsCreated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creado cuentas de usuario: ", _interpolate(_named("created")), " de ", _interpolate(_named("count"))])},
        "accountsCreatedText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se pudo crear el ", _interpolate(_named("error")), ". Nos parece que un error en соответсвующих líneas. Corrija los errores y descargue de nuevo el documento."])},
        "kickModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desacoplar de la institución"])},
        "kickModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga cuidado. Los usuarios pierden el acceso a les expedido por la organización de las licencias."])},
        "kickModalTextSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga cuidado. El usuario perderá el acceso a expedido por la organización de las licencias."])},
        "waitingQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperamos la cola..."])},
        "creatingUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creamos los usuarios..."])},
        "usersCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios creados..."])},
        "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberar"])},
        "handleErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratamos de error..."])},
        "exportingFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para exportar un archivo"])}
      },
      "fileFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descarga el documento no se ajusta al formato requerido"])},
      "fileName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El archivo de ", _interpolate(_named("date"))])},
      "changeClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la clase de"])},
      "groupDeleteConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Para eliminar ", _interpolate(_list(0)), ", escriba su nombre en el cuadro de abajo."])},
      "noInviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este momento no tiene el enlace activo para la entrada en la institución"])},
      "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un grupo de usuarios (XLS)"])},
      "inviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La referencia de la invitación"])},
      "userRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud de un usuario: "])},
      "changeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar el grupo de"])},
      "infoTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Este botón abre el panel de la información sobre su centro."]), _normalize(["Aquí usted será capaz de crear y editar grupos, en los que va a combinar sus estudiantes y profesores. Por lo tanto puede controlar filiales o separarse de los moderadores de los estudiantes."]), _normalize(["Para compartir un enlace en una entrada en su Organización - haga clic en el botón de la derecha"])])},
      "actionFileCreateTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Para añadir un montón de usuarios a la vez, descargue el archivo en formato \".xls\". En el contenido del archivo debe incluir el nombre, la función y el E-mail de los usuarios. A estas direcciones a los usuarios recibirán una invitación para el registro de entrada en la guía de aprendizaje de la Institución."]), _normalize(["Si sus alumnos no las direcciones de correo electrónico, entonces usted puede invitar a unirse a la institución mediante un código QR o un enlace de la invitación."])])},
      "downloadFileTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar .xls plantilla"])}
    },
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un usuario"])},
    "classNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La estación de"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])}
    ],
    "limb": {
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrado"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baneado"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La razón"])},
      "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado"])}
    }
  },
  "deleteUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eliminación de usuarios"]), _normalize(["Eliminación de un usuario"]), _normalize(["Retiro de la institución"])])},
  "deleteUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tenga cuidado, esta acción es irreversible, usuarios eliminados no se pueden recuperar."]), _normalize(["Está seguro de que desea eliminar el usuario?"]), _normalize(["Está seguro de que desea eliminar de la institución?"])])},
  "banUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bloqueo de usuarios"]), _normalize(["Bloqueo de usuario"]), _normalize(["Bloqueo de la fundadora de la"])])},
  "banUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["El bloqueo limita al usuario el acceso a los laboratorios, y si el usuario es miembro fundador, no será capaz de controlar su institución."]), _normalize(["El bloqueo limita al usuario el acceso a los laboratorios."])])},
  "banReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La causa del bloqueo"])},
  "unbanUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Desbloqueo de usuario"]), _normalize(["Liberación del fundador"])])},
  "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El portal"])},
  "vrLabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los laboratorios virtuales"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo siento"])},
  "loader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrancamos..."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con éxito"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salió"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
  "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no se ha seleccionado"])},
  "goByLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado"])},
  "notPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha publicado"])},
  "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se publica"])},
  "toMainPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de inicio"])},
  "addApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar la aplicación"])},
  "commonVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generales tutoriales"])},
  "commonDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos compartidos"])},
  "commonSysReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisitos de sistema estándar"])},
  "systemReqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estándar VERSO"])},
  "labelDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos"])},
  "titleAnnouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La creación de un anuncio de producto"])},
  "titleProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La creación de un producto"])},
  "EditProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La edición del producto"])},
  "tabMainData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos básicos"])},
  "tabInfoData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información sobre el producto"])},
  "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar el producto"])},
  "labelImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La imagen"])},
  "labelAnnouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El anuncio"])},
  "labelVideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutoriales"])},
  "labelFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo"])},
  "tooltipSyncLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar <br> con la localización de la"])},
  "tooltipSpendTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tiempo total en el laboratorio"])},
  "tooltipLastLaunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de la última ejecución de"])},
  "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
  "someText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tiene perseverancia - publica el labu con un intento de una"])},
  "someText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y esta es la opción, si es urgente-urgente hacer el anuncio"])},
  "btnH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El título de la"])},
  "btnP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Párrafo"])},
  "btnUnnumberedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De la lista sin numerar"])},
  "btnNumberedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una lista numerada"])},
  "regData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos de registro"])},
  "btnBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las negritas"])},
  "btnItalic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursiva"])},
  "btnCross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tachado de"])},
  "btnAddLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir un enlace"])},
  "btnAddCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir código de"])},
  "btnCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El bloque de código"])},
  "btnQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cita"])},
  "btnHorizontalDivider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separador horizontal"])},
  "btnNewLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un salto de línea"])},
  "btnClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar el formato de"])},
  "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "btnRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
  "fields": {
    "article_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nº de artículo"])},
    "img_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La imagen de fondo"])},
    "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El icono de la aplicación"])},
    "app_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un enlace a una aplicación"])},
    "supported_locales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los idiomas de la aplicación"])},
    "locales": {
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruso"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francés"])},
      "hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenio"])}
    },
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sección"])},
    "subsection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El objeto de la"])},
    "img_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La imagen de la tarjeta"])},
    "demo_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El modo de demostración"])},
    "demo_mode_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de arranques"])},
    "has_workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La presencia del cuaderno"])},
    "workbook_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La referencia al cuaderno de"])},
    "registry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre en el registro de"])},
    "registry_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nº en el registro"])},
    "price_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio por mes"])},
    "discount_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento por mes"])},
    "labelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la"])},
    "labelDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "labelAuthorSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor de la firma"])},
    "labelSystemReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisitos del sistema"])},
    "labelIndividualSystemReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuales ARTÍCULO"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El texto del botón"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace"])}
  },
  "btnSetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalar/Reparar el enlace"])},
  "textToMarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscríbase ahora y obtenga acceso a un laboratorio!"])},
  "textToMarketMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscríbase ahora!"])},
  "confirmRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas seguro que quieres eliminar el producto?"])},
  "buttons": {
    "addByLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir un enlace"])},
    "loadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar el archivo"])},
    "addLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una transición"])}
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprimir"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La búsqueda de"])},
  "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La búsqueda de"])},
  "activeLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencias activas"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado!"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copiar en el portapapeles"])},
  "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio de la"])},
  "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización de la"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago se realiza correctamente"])},
  "typeTextToFind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba el texto de búsqueda"])},
  "sessionCloseByServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión cerrada. Es necesario pasar de nuevo el proceso de autenticación."])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por desgracia, no hemos encontrado lo que querían 🥺"])},
  "accessDeniedPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya, parece que no tienes 😕"])},
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "alerts": {
    "sessionClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión cerrada"])},
    "sessionCloseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por desgracia, así es. Para continuar, inicie sesión con su cuenta de usuario."])},
    "fileNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encuentra el archivo, descargue de nuevo"])},
    "fileLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargamos el archivo..."])},
    "missingFileLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se indica el nombre o la referencia a un archivo"])},
    "leavePageWithUnsavedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios pueden no conservarse"])},
    "minFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño mínimo de la"])},
    "requiredSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño que desee"])},
    "maxFileWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño máximo de archivo"])},
    "temporaryUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamos a volver!"])},
    "temporaryUnavailableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, en laboratorios virtuales también debe cumplir con las reglas y poner las cosas en orden. Pronto terminaremos la limpieza, y será mejor que la anterior!"])}
  },
  "notifications": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con éxito"])},
    "successSaveAnnouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El anuncio se ha guardado correctamente"])},
    "successSaveProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El producto se ha guardado correctamente"])},
    "inPublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trata de la publicación de"])},
    "orgShopError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No rellenado todos los campos correctamente"])},
    "fileExtension": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Permitido el formato de los datos ", _interpolate(_named("extension"))])},
    "productPublishedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tarjeta de producto ", _interpolate(_named("name")), " se ha publicado correctamente"])},
    "productPublishedError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se pudo publicar la tarjeta de producto ", _interpolate(_named("name")), "."])},
    "productPublishedErrorDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error: ", _interpolate(_named("error"))])}
  },
  "pageTitles": {
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los precios"])},
    "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tienda"])},
    "Laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "Laboratory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El laboratorio ", _interpolate(_named("name"))])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
    "SessionClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la sesión"])},
    "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La gestión de"])},
    "AdministrationUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " la Lista de usuarios"])},
    "AdministrationUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión De ", "|", " El Usuario"])},
    "AdministrationLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión De ", "|", " La Licencia"])},
    "AdministrationRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión De ", "|", " La Solicitud"])},
    "AdministrationUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " Crear un usuario"])},
    "AdministrationLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión De ", "|", " El Limbo"])},
    "AdministrationOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión De ", "|", " La Institución"])},
    "AdministrationOrgOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión De ", "|", " El Fundador De La"])},
    "AdministrationOrgUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " los Usuarios de la institución"])},
    "AdministrationOrgUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " el Usuario de la institución"])},
    "AdministrationOrgOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " Pedidos de la institución"])},
    "AdministrationOrgRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " Solicitud de entrada en la institución"])},
    "AdministrationOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " la Solicitud de adhesión a la institución"])},
    "AdministrationOrgLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " la Licencia de la institución"])},
    "AdministrationOrgUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " Creación de un usuario de la institución"])},
    "AdministrationOrganizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " una Lista de las instituciones"])},
    "AdministrationOrgCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de ", "|", " la Creación de una institución"])}
  },
  "labels": {
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "patronymic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre patronímico"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El idioma del sitio y de las aplicaciones"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El papel de la"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La escuela"])},
    "organization1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La institución"])},
    "studyClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clase"])},
    "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de la universidad"])},
    "userType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El papel de la"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El país"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La región"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ciudad"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña"])},
    "localRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles locales"])},
    "requestedRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitada la función de"])},
    "requestedClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clase solicitada"])},
    "fileAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjunte el archivo"])},
    "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del grupo"])},
    "addGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un grupo de"])},
    "notSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se especifica"])},
    "classNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin clase"])},
    "groupNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin grupo"])},
    "founderData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos de la fundadora de la"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El acceso"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transiciones"])},
    "tournament": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Физбой"])},
    "workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuaderno"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conservación de la"])}
  },
  "languages": {
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruso"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հայերեն"])}
  },
  "roles": {
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El administrador de la"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El moderador"])},
    "marketer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un vendedor de la"])},
    "org_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El titular de la institución"])},
    "org_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El moderador de la institución"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El maestro"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estudiante"])},
    "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obligatorio."])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo debe contener un mínimo ", _interpolate(_named("min")), " caracteres."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo debe contener un máximo de ", _interpolate(_named("max")), " caracteres."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo debe ser válida a la dirección de correo electrónico."])},
    "datepickerRangeOverflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es demasiado grande la fecha"])},
    "datepickerRangeUnderflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demasiado pequeña la fecha"])},
    "datepickerTypeMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la fecha en el formato dd.mm.aaaa"])},
    "valueMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El valor es obligatorio"])},
    "incorrectGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrecta de nombre de un grupo de"])}
  },
  "filters": {
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clase"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El papel de la"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "vizex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizex"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estado de"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registros"])},
    "buttons": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial"])}
    }
  },
  "menus": {
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tienda"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La gestión de"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La entrada"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La salida"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los precios"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La licencia"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud de adhesión de"])},
    "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De la institución"])},
    "limb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El limbo"])},
    "ownersRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La propuesta de los fundadores de la"])}
  },
  "footer": {
    "licenseAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta pública"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones de uso"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte técnico"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Versión: ", _interpolate(_named("version"))])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© Зенкова olga alexandrovna, ", _interpolate(_named("date")), " © sa \"42\", ", _interpolate(_named("date"))])}
  },
  "half_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seis meses"])},
  "tooltipLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La licencia caduca"])},
  "tooltipLicenseExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La licencia ha caducado"])},
  "tooltipLaunchHasLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha agotado el límite de lanzamientos"])},
  "tooltipAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a través de"])},
  "tooltipLockNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo el Administrador puede<br>agregar nuevos productos"])},
  "tooltipLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe iniciar sesión para iniciar en el modo de demostración"])},
  "lessOneMinute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menos de 1 min"])},
  "requiredField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El campo ", _interpolate(_named("fieldName")), " es obligatorio"])},
  "ruleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la dirección de correo electrónico válida"])},
  "ruleUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la dirección url correcta"])},
  "ruleMoreZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser mayor que 0"])},
  "createProductCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una tarjeta de producto"])},
  "usersTableColumns": {
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La institución"])},
    "added_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadido"])},
    "registered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de registro"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La licencia"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Nombre, Apellido"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El papel de la"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clase"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El grupo"])}
  },
  "orgsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los participantes"])},
    "licenses_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencias"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El fundador de la"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadido"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
  },
  "statuses": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nueva"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espera"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobada"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazada"])}
  },
  "imagesAlt": {
    "vrLabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los laboratorios virtuales"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La imagen"])},
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
    "potato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papas"])},
    "ufo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un objeto volador no identificado"])}
  },
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La licencia"])},
  "licenses": {
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuales"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De la institución"])}
  },
  "autoPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоплатеж"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El estado de"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa"])},
  "packet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El paquete"])},
  "allInclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo incluido"])},
  "unbanUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Liberación restaurará el acceso del usuario a los laboratorios."]), _normalize(["Liberación dará lugar a la recuperación de acceso, fundador de la administración de la institución."])])},
  "kickUserFromOrgModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La exclusión de la institución"])},
  "kickUserFromOrgModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La excepción dará lugar a la pérdida de acceso al usuario expedido por las licencias."])},
  "requestsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº solicitud"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Nombre, Apellido"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El papel de la"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clase"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solución"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesado"])},
    "statusDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de la decisión"])}
  },
  "numerals": {
    "labs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nn")), " laboratorio"]), _normalize([_interpolate(_named("nn")), " laboratorio"]), _normalize([_interpolate(_named("nn")), " laboratorios"])])},
    "students": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["el alumno"]), _normalize(["estudiantes"])])},
    "teachers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["el maestro"]), _normalize(["profesor"]), _normalize(["profesores"])])},
    "users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["el usuario"]), _normalize(["usuario"]), _normalize(["usuarios"])])},
    "createUsers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["usuario"]), _normalize(["usuario"]), _normalize(["usuarios"])])},
    "moderators": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["el moderador"]), _normalize(["moderador"]), _normalize(["moderadores"])])}
  },
  "emailNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el correo está pendiente de confirmación"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algo salió mal..."])},
  "userCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario se ha creado correctamente"])},
  "userCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un"])},
  "emptyUserName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El usuario nº", _interpolate(_named("id"))])},
  "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró nada"])},
  "createOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Creación De Una Institución"])},
  "organizationCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La institución ha creado correctamente"])},
  "organizationCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear otra"])},
  "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El laboratorio"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caduca"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автопродление"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial"])},
  "youHaveNLaunchesLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Te enfrentas a ", _interpolate(_named("nn")), " inicio"]), _normalize(["Tienes ", _interpolate(_named("nn")), " inicio"]), _normalize(["Tienes ", _interpolate(_named("nn")), " lanzamientos"])])},
  "usersListTableLeaveBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pasar a la página de un usuario, la selección de esta página, se pierde"])},
  "AdministrationOrdersConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La confirmación del pago"])},
  "AdministrationOrdersConfirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprobada el pago no se puede deshacer"])},
  "AdminOrgOrdersToastOrderConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido pagado!"])},
  "ShopOrgTableAddNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agregar el lote"])},
  "universalEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer más"])},
  "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomar"])},
  "declineRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar la solicitud"])},
  "acceptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar y aceptar la solicitud"])},
  "actionConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme la acción"])},
  "searchByContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar en el contexto de la"])},
  "searchByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda por nombre de"])},
  "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecciona"])},
  "notEnoughLicensesToDoThisAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no es suficiente licencias para la acción"])},
  "fileExtensionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El archivo \"", _interpolate(_named("name")), "\" no tiene un formato válido (", _interpolate(_named("extension")), ")."])},
  "exportFromFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "importFileWithError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar el archivo con los errores"])},
  "exported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportado"])},
  "subscribePage": {
    "confirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para la activación de автоплатежа debe realizar una compra en 1rub para la verificación de la tarjeta."])},
    "emptyPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por desgracia, aún no tiene el pago de las suscripciones, entrar en la tienda, hay muchas cosas interesantes."])},
    "packetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Están disponibles de laboratorio:"])},
    "toShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la tienda"])}
  },
  "price": {
    "currencyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUB"])},
    "currencySign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["₽"])},
    "monthPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / mes"])},
    "salePercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descuento"])},
    "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El precio"])}
  },
  "labs": {
    "licenseState": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial"])},
      "own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suscripción"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De la organización de"])}
    },
    "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recientes"])},
    "emptySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafortunadamente, su pregunta no se encontró nada. Puede ser usted busca algo en nuestra biblioteca?"])},
    "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suscripción"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto"])},
    "fromPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["de ", _interpolate(_named("price"))])},
    "launch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscribirse"])},
    "workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cuaderno de trabajo"])},
    "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La demo"])},
    "virtualLab": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laboratorio virtual \"", _interpolate(_named("name")), "\""])},
    "clearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claro"])},
    "mobileModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En los dispositivos móviles la aplicación todavía no está disponible."])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh..."])}
  },
  "time": {
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
  },
  "prepositions": {
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  }
}