<template>
  <FallbackPage
    :title="t('alerts.temporaryUnavailable')"
    titleSize="medium"
    imagePosition="background"
    class="back-soon"
  >
    <template #text>
      <p class="back-soon__text">
        {{ t("alerts.temporaryUnavailableText") }}
      </p>
    </template>

    <template #image>
      <div class="back-soon__figure" />
    </template>
  </FallbackPage>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import FallbackPage from '@/components/FallbackPage.vue';

const { t } = useI18n();
</script>

<style lang="scss">
.back-soon {
  &__figure {
    width: 374px;
    height: 187px;
    background-color: #E2EFF5;
    border-radius: 187px 187px 0 0;
    margin: 0 auto;
  }

  h1 {
    width: max-content;
  }

  &__text {
    margin-top: 25px;
  }
}
</style>
