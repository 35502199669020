<template>
  <div class="container fallback">
    <div class="fallback__top">
      <div
        class="fallback__img-container"
        :class="`fallback__img-${imagePosition}`"
      >
        <slot name="image">
          <picture>
            <source
              srcset="/img/empty/webp/empty.webp"
              type="image/webp"
            />
            <img
              src="/img/empty/empty.png"
              :alt="t('imagesAlt.vrLabs')"
            />
          </picture>
        </slot>
      </div>

      <h1
        v-if="title"
        class="fallback__title"
        :class="`fallback__title_${titleSize}`"
      >
        {{ title }}
      </h1>
    </div>

    <div
      v-if="$slots.text"
      class="fallback__text"
      :class="{ left: textAlignLeft }"
    >
      <slot name="text" />
    </div>

    <slot
      name="actions"
      :buttonClass="$style.fallback__btn"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps({
  title: {
    type: String,
    default: '',
  },
  titleSize: {
    type: String,
    default: 'large',
    validator: (size: string) => ['large', 'medium'].includes(size),
  },
  textAlignLeft: {
    type: Boolean,
    default: false,
  },
  imagePosition: {
    type: String,
    default: 'top',
    validator: (position: string) => ['top', 'background'].includes(position),
  },
});

const { t } = useI18n();
</script>

<style lang="scss">
@use '@/assets/style/mixins' as m;
@import "@/assets/style/include.scss";

.fallback {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  z-index: 1;
  flex-grow: 1;

  &,
  &__top {
    @include m.flex-center;
    flex-direction: column;
  }

  &__top {
    position: relative;

    &:has(.fallback__title_medium) {
      margin-bottom: 24px;
    }

    &:has(.fallback__title_large) {
      margin-bottom: 40px;
    }
  }

  &__img-background {
    + .fallback__title {
      position: absolute;
      bottom: 5%;
    }
  }

  &__img-container {
    margin-bottom: 8px;
    font-size: 0;
  }

  &__img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title {
    line-height: 1.2;

    &_large {
      font-size: 6rem;
    }

    &_medium {
      font-size: 3rem;
    }
  }

  &__text {
    font-size: 1.25rem;
    max-width: 560px;
    line-height: 1.8;

    &:not(.left) {
      text-align: center;
    }

    &.left {
      text-align: left;
    }

    :deep(p) {
      margin: 1em 0;
    }
  }

  &__btn {
    font-weight: 500;
    background: var(--color-grey-header);
    padding: 8px 30px;
    border-radius: 5px;
    &:hover {
      background: var(--color-acent-lighter);
    }
  }

  @include media-breakpoint-down("sm") {
    &__text {
      font-size: 1rem;
      padding: 30px 18px;
    }
  }
}
</style>

<style module lang="scss">
.fallback__btn {
  font-weight: 500;
  background: var(--color-grey-header);
  padding: 8px 30px;
  border-radius: 5px;
  &:hover {
    background: var(--color-acent-lighter);
  }
}
</style>
