import axios, { AxiosResponse, AxiosError } from 'axios';
import store from '@/store';
import router from '@/router/index';
import { names, paths } from '@/constants/routes';
import { logoutUser } from '@/services/userActions';
import { ErrorCodeEnum } from '@/types/httpResponses';

const apiClient = axios.create({
  baseURL: '/api',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    crossDomain: true,
  },
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const isUserGuest = store.getters.isGuest;

    // eslint-disable-next-line default-case
    switch (error.response?.status) {
      case ErrorCodeEnum.NotFound:
        router.replace({ name: names.alerts.notFound });
        break;
      case ErrorCodeEnum.AccessDenied:
        router.replace({ name: names.alerts.accessDenied });
        break;
      case ErrorCodeEnum.NotAuthorized:
        if (!isUserGuest) {
          logoutUser(paths.alerts.sessionClosed);
        }
        break;
    }

    return Promise.reject(error);
  }
);

export default apiClient;
